import axios from 'axios'
import { history } from "../history";
// import store from '../store/store'
// import router from '../router'

// console.log(process.env)
const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000000,
  headers: {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
})

const getAuthToken = () => localStorage.getItem('token')
// const setAuthToken = (token) => localStorage.setItem('token', token)
// const setUser = (user) => localStorage.setItem('user', user)

const showSpinner = () => {
  try {
    document.getElementById('loading-spinner').style.display = 'block';
  } catch (error) {
  }
}

const hideSpinner = () => {
  try {
    document.getElementById('loading-spinner').style.display = 'none';
  } catch (error) {
  }
}

const authInterceptor = (config) => {
  showSpinner();
  config.headers['Authorization'] = `Bearer ${getAuthToken()}`
  return config
}

const errorInterceptor = async (error) => {
  hideSpinner()
  console.log('Error interceptor', error.response.status)
  if (error.response) {
    switch (error.response.status) {
      case 400:
        console.error(error.response.status, error.message);
        // store.dispatch('notification/danger', {title: 'Nothing to display', text: 'Data Not Found'})
        break;

      case 403:
        // alert("contrasena invalida");
        // store.dispatch('notification/danger', {title: 'Nothing to display', text: 'Data Not Found'})
        break;

      // eslint-disable-next-line no-case-declarations
      case 401:
        // const titleAuth = error.response.data && error.response.data.message === 'email or password incorrect' ? 'Email y/o contraseña incorrecta' : 'Por favor inicie sesion nuvamente'
        // const textAuth = error.response.data && error.response.data.message === 'email or password incorrect' ? 'Verifique los datos ingresados' : 'Por favor inicie sesion nuvamente'
        // store.dispatch('notification/danger', { title: titleAuth, text: textAuth })

        localStorage.removeItem("token");
        history.push("/login");
        break;

      // eslint-disable-next-line no-case-declarations
      case 422:

        if (error.response.data.message) {
          localStorage.setItem("error422", 'Error: ' + error.response.data.message);
        } else if (error.response.data.errors) {
          let message = [];
          error.response.data.errors.forEach(error => {
            message.push(error.msg);
          })

          localStorage.setItem("error422array", JSON.stringify(message));
        }
        // const title = "Error";
        // let text = "";
        // const values = Object.values(error.response.data.message);
        // values.forEach((element) => {
        //   text += `${element} <br>`;
        // });
        // store.dispatch('notification/danger', {title, text})
        break;

      default:
        console.log(`errorInterceptor -> `, error);
      // store.dispatch('notification/danger',  {title: `${error.response.status}`, text: error.message})
    }
    return Promise.reject(error)
  } else {
    console.log(`else errorInterceptor -> ${typeof error}`, error);
    if(typeof error == 'string') {
      // const err = error.split('at');
      // store.dispatch('notification/danger',  {title: `Ocurrió un error.`, text: `${err[0]}`})
    } else {
      console.log(`errorInterceptor -> ${Object.keys(error)}`);
      // store.dispatch('notification/danger',  {title: 'Ocurrió un error.', text: 'Error de red.' })
    }
  }
}

const responseInterceptor = (response) => {
  hideSpinner()
  switch (response.status) {
  case 200:
    return response.data
  case 204:
    // store.dispatch('notification/success', {title: 'Exito!', text: 'Te enviamos un email con el nuevo acceso'})
    return response
  default:
    return response
  }
}

service.interceptors.request.use(authInterceptor)
service.interceptors.response.use(responseInterceptor, errorInterceptor)

export default service